import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const app = firebase.initializeApp({
  apiKey: "AIzaSyDu2Sssc74wWHL8PLJwd8-RHhgDT5F1SJE",
  authDomain: "fishingplaces-dev.firebaseapp.com",
  projectId: "fishingplaces-dev",
  storageBucket: "fishingplaces-dev.appspot.com",
  messagingSenderId: "617424973459",
  appId: "1:617424973459:web:54284841f3245be64be766"
});

export const auth = app.auth();

export const db = getFirestore(app);

export default app;

export const storage = getStorage(app);
